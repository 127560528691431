import React from 'react'
import styled from 'styled-components'
import { ReUseHOneTag } from './ReUseHOneTag'

const SectionWrapper = styled.div`
  max-width: 1920px;
  margin: auto;
  width: 96vw;
  display: flex;
  flex-direction: column; /* Stack badges and content vertically */
  padding: 50px 20px;

  .badges-and-image-container {
    display: grid;
    grid-template-columns: 1fr auto; /* Left side (badges-section) and right side (image-container) */
    align-items: flex-start; /* Align items at the top */
    margin-bottom: 40px; /* Space between badges/image and content */

    @media (max-width: 768px) {
      grid-template-columns: 1fr; /* Stack columns vertically */
      justify-items: center; /* Center items horizontally */
      text-align: center; /* Center-align text */
    }
  }

  .image-container {
    text-align: right; /* Align image to the right */

    img {
      max-width: 100%;
      height: auto;
      border: none;
    }

    @media (max-width: 768px) {
      text-align: center; /* Center image horizontally */
      margin-top: 20px; /* Add space between content and image */
    }
  }

  .content-section {
    grid-column: 1 / 2; /* Place content-section in the first column (left side) */
    display: flex;
    flex-wrap: wrap;
    gap: 20px; /* Space between description and image */

    .description {
      flex: 1;
      min-width: 700px;
      color: #1f1f1f;
      font-size: 1rem;
      line-height: 1.5;
      @media ((max-width: 1200px) and (min-width: 768px)) {
        min-width: 500px;
      }

      p {
        @media (min-width: 1650px) {
          font-size: 1.5rem;
        }
      }

      @media (max-width: 1090px) {
        font-size: 0.9rem;
        padding-top: 8px;
      }

      @media (max-width: 768px) {
        min-width: unset; /* Remove min-width for smaller screens */
        text-align: center; /* Center-align text */
      }

      a {
        color: #047cc2; /* Link color */
        text-decoration: none; /* Remove underline */
        font-weight: bold; /* Make the link bold */

        &:hover {
          text-decoration: underline; /* Add underline on hover */
        }
      }
    }
  }

  .thank-you-section {
    height: 60vh;
    flex: 1;
    min-width: 300px;
    max-width: 450px;
    background: #fff;
    padding: 20px;
    margin-top: 12px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h2 {
      color: #1f1f1f;
      font-size: 1.5rem;
      margin-bottom: 10px;
    }

    p {
      color: #1f1f1f;
      font-size: 1rem;
      margin-bottom: 20px;
    }

    .support-text {
      margin-top: 60px;
      font-size: 0.8rem;
      color: #888;
    }
  }

  .SpendMattersCLMBannerHeading {
    text-align: left;
    margin-top: 55px;
    font-size: 2.2rem;
    color: #047cc2;

    @media (max-width: 1920px) {
      font-size: 3.2rem;
    }

    @media (max-width: 1650px) {
      font-size: 2.5rem;
    }

    @media (max-width: 1090px) {
      font-size: 2rem;
    }

    @media (max-width: 768px) {
      text-align: center; /* Center-align heading */
      font-size: 1.5rem;
      margin-top: 20px; /* Adjust margin for smaller screens */
    }

    @media (max-width: 425px) {
      padding-top: 15px;
      font-size: 1.2rem;
    }
  }
  .quote {
    font-size: 1.3rem;
    font-weight: bold;
    margin-top: 20px;
    color: #047cc2;
    text-align: left;
    margin-left: 0;
    @media (min-width: 1650px) {
      font-size: 1.5rem;
    }
    @media (max-width: 1024px) {
      font-size: 1.3rem;
      text-align: center;
    }
    @media (max-width: 768px) {
      font-size: 1rem;
      text-align: center;
    }
  }
`

export const AppSpendMattersCLMBadgeSection = () => {
  return (
    <SectionWrapper>
      {/* Badges and Image Container */}
      <div className="badges-and-image-container">
        <div>
          {/* Content Section */}
          <div className="content-section">
            <div className="description">
              <ReUseHOneTag
                Heading="We are proud of what our customers think about Raindrop Contract Lifecycle Management (CLM)"
                HeadingStyle="SpendMattersCLMBannerHeading"
              />
              <p>
                Our customers had a lot to say when it came to rating Raindrop solutions for the{' '}
                <a
                  href="https://insider.spendmatters.com/free_dashboard/?next=%2Fdashboard%2F"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Spend Matters
                </a>{' '}
                Spring 2025 SolutionMap. Participants, like Raindrop, are scored on technical capability and customer
                ratings; we think the results speak for themselves.
              </p>
              <blockquote className="quote">
                "Strong support for projects within the contracts module. — Support for templates and native contract
                authoring is strong against top competitors."
              </blockquote>
            </div>
          </div>
        </div>

        {/* Image Container */}
        <div className="image-container">
          <img
            src="https://storage.googleapis.com/raindroppublic/website_data/Spend-Matters-CLM-SXM-Enterprise-Banner-img.png"
            alt="Gartner Hype Cycle for Procurement and Sourcing Solutions 2024"
          />
        </div>
      </div>
    </SectionWrapper>
  )
}
